import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useQuery } from 'hooks/useQuery'
import { useEffect, useState, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReservationService } from 'services/Reservations/Service'
import { formHasTickets } from '../Booking.utils'

const isUUID = (string = '') => /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(string)

export const useBookingLink = () => {
  const [reservationId, setReservationId] = useState()
  const {
    id: activityId,
    activity: { companyId: activityCompanyId, type }
  } = useActivity()

  const history = useHistory()
  const [{ hour, date = '' }] = useQuery()
  const { add: addToast } = useToast()
  const { reservationCode } = useParams()

  const basePath = useMemo(() => {
    if (type === 'Experiences::Transport') {
      return `/transport/${activityId}`
    }

    if (type === 'Experiences::OfflineExperience') {
      return ''
    }

    return `/activity/${activityId}`
  }, [activityId, type])

  useEffect(() => {
    if (!isUUID(reservationCode)) {
      ReservationService.getByReservationCode(reservationCode)
        .then(({ id }) => setReservationId(id))
        .catch(() => {
          addToast('Reserva não encontrada')
          history.push(`${basePath}/bookings?date=${date}`)
        })
    } else {
      setReservationId(reservationCode)
    }
  }, [reservationCode, addToast, date, history, basePath])

  useEffect(() => {
    if (!hour || !date) {
      history.push(`${basePath}/bookings`)
    }
  }, [hour, date, history, basePath])

  const [isWaitingResponse, setWaitingResponse] = useState(false)

  const createRedirect = (redirectTo = identity) => async data => {
    if (isWaitingResponse) {
      return
    }

    const isFormValid = formHasTickets(data)

    if (isFormValid) {
      try {
        setWaitingResponse(true)
        await ReservationService.addTickets(activityId, activityCompanyId, reservationId, data)
        addToast('Vinculação realizada com sucesso', 'success')
        setWaitingResponse(false)
        history.push(redirectTo())
      } catch (error) {
        setWaitingResponse(false)
        const { parsedErrors = [] } = error
        parsedErrors.length ? parsedErrors.forEach(errorMessage => addToast(errorMessage, 'error', 10000)) : addToast('Houve um erro no cadastro da sua reserva')
      }
    } else {
      addToast('Adicione ingressos na reserva')
    }
  }

  const onFinish = createRedirect(() => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect(() => `/reservation/${reservationId}?selectedNav=ingressos`)

  return {
    onFinish,
    onContinue
  }
}
