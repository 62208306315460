/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { H1, Accordion, CollapsableSection, useToast } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { CreateTransportInfo } from './CreateTransportInfo'

const marginBottom5 = marginBottom(5)

export const CreateTransport = () => {
  const { add: addToast } = useToast()
  const onAccordionClick = value => value !== 0 && addToast('Preencha as informações básicas')

  return (
    <>
      <H1 css={marginBottom5}>Rota de transporte</H1>
      <Accordion value={1} onClick={onAccordionClick}>
        <CollapsableSection
          indicator={1}
          title='Informações básicas'
          topics='Registro / Contato / Endereço de origem / Endereço de destino'
        >
          <CreateTransportInfo />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Infraestrutura / Nível de dificuldade'
        />

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso no transporte / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre o transporte em plataformas de vendas'
        />

        <CollapsableSection indicator={4} title='Vagas' topics='Limite de vagas / Horários' />

        <CollapsableSection
          indicator={5}
          title='Tarifário'
          topics='Tarifário padrão / Tipificação de PAX / Tarifário por demanda'
        />

      </Accordion>
    </>
  )
}
