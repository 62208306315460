/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useActivity } from 'contexts/Activity'
import { CompanyActivities, OfflineExperiences, PartnerActivities } from './ActivitiesList'
import { flexColumn, LoadingAnimation } from '@bonitour/components'
import { loadingContainer } from 'assets/styles/global'
import { useEffect, useState } from 'react'

export const ActivityListSelector = ({
  subdomain,
  showCreateButton = true,
  activitiesMaxHeight,
  partnersMaxHeight,
  onNavigation: emitNavigationEvent = identity,
  withOfflineExperiences = false,
  email = '',
  ...other
}) => {
  const { companyActivities = [], partnerActivities = [], loading, updateList } = useActivity()
  const [listFetched, setListFetched] = useState(false)

  useEffect(() => {
    if (!loading && !companyActivities?.length && !partnerActivities?.length && !listFetched) {
      updateList()
      setListFetched(true)
    }
  }, [loading, companyActivities, partnerActivities, listFetched, updateList])

  if (loading) {
    return (
      <LoadingAnimation css={loadingContainer} />
    )
  }

  return (
    <div css={flexColumn} {...other}>
      <CompanyActivities
        activitiesMaxHeight={activitiesMaxHeight}
        companyActivities={companyActivities}
        showCreateButton={showCreateButton}
        onNavigation={emitNavigationEvent}
        subdomain={subdomain}
        email={email}
      />
      {withOfflineExperiences && (
        <OfflineExperiences
          activitiesMaxHeight={activitiesMaxHeight}
          onNavigation={emitNavigationEvent}
          subdomain={subdomain === 'bookings' ? 'bookings/create' : subdomain}
          email={email}
        />
      )}
      <PartnerActivities
        partnersMaxHeight={partnersMaxHeight}
        partnerActivities={partnerActivities}
        onNavigation={emitNavigationEvent}
        subdomain={subdomain}
        email={email}
      />
    </div>
  )
}
