/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import moment from 'moment'
import { DateIndicator, EditInfoIcon, TooltipOnHover } from '@bonitour/components'
import { CSSTransition } from 'react-transition-group'
import { identity } from '@bonitour/common-functions'
import { useHover } from 'hooks/useHover'
import { useFeatureFlag } from 'contexts/Feature'

const dateIndicator = css`
  margin: 10px auto;
`

const fadeAnimation = css`
  animation: effect 500ms ease;
  @keyframes effect {
    0%   {
      opacity: 0;
    }
    100%   {
      opacity: 1;
    }
  }
`

const exceptionalDayEditIcon = css`
  cursor: pointer;
  visibility: hidden;
`

const visible = css`
  visibility: visible;
`

export const VacancyTableDateIndicator = ({ date, isExceptionalDay = true, onExceptionalDayEditClick: emitExceptionalDayEditClick = identity, isActivityOwner = false }) => {
  const [isExceptionalDayEnabled] = useFeatureFlag('orb-exceptional-day')
  const dateObject = new Date(moment(date).valueOf())
  const exceptionalDate = moment(date).format('YYYY-MM-DD')
  const [isVisible, showOnHover, hideOnHover] = useHover()

  return (
    <CSSTransition key={date} timeout={0}>
      <div css={fadeAnimation} onMouseLeave={hideOnHover} onMouseOver={showOnHover}>
        {(isActivityOwner && isExceptionalDayEnabled)
          ? (
            <TooltipOnHover text='Dia excepcional' size='120'>
              <EditInfoIcon css={[exceptionalDayEditIcon, isExceptionalDay && isVisible && visible]} onClick={emitExceptionalDayEditClick(exceptionalDate)} />
            </TooltipOnHover>
          )
          : null
        }
        <DateIndicator css={dateIndicator} date={dateObject} />
      </div>
    </CSSTransition>
  )
}
