/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { AngleThickRightIcon, TooltipOnHover } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useHistory } from 'react-router-dom'
import { SwatchImage } from 'components/SwatchImage'
import { activityCard, extendedActivityCard, activitiesPageCardImage, title, titleMaxWidth, partnerTitle, activitiesPageCardCompanyInfo, activityName, link, seeMoreIconPosition, labelContainer, titleTooltipContainer } from './ActivityCard.style'
import { marginRight } from 'assets/styles/global'
import { useMemo } from 'react'

const marginRight10 = marginRight(10)

export const ActivityCard = ({
  activity = {},
  onActivityClick: emitActivityClick = identity,
  subdomain = 'dashboard',
  isFromActivitiesPage,
  hasTitleMaxWidth = true,
  showNameTooltip = false,
  disableLink = false,
  email = '',
  ...other
}) => {
  const { push } = useHistory()
  const { id, name, companyName, image, isPartner = false, type } = activity
  const { setId } = useActivity()

  const basePath = useMemo(() => {
    if (type === 'Experiences::Transport') {
      return `/transport/${id}`
    }

    if (type === 'Experiences::OfflineExperience') {
      return `/offline-experience/${id}`
    }

    return `/activity/${id}`
  }, [id, type])

  const onNavMenuClick = () => {
    setId(id)
    emitActivityClick()
    if (subdomain !== null && !disableLink) {
      push(`${basePath}/${subdomain}${email ? `?email=${email}` : ''}`)
    }
  }

  const companyNameLabel = useMemo(() => {
    if (!isPartner) {
      return null
    }

    return showNameTooltip
      ? <TooltipOnHover css={titleTooltipContainer} text={companyName}>
        <p css={[title, hasTitleMaxWidth && titleMaxWidth, isPartner && partnerTitle]}>{companyName}</p>
      </TooltipOnHover>
      : <p css={[title, hasTitleMaxWidth && titleMaxWidth, isPartner && partnerTitle]}>{companyName
      }</p>
  }, [companyName, hasTitleMaxWidth, isPartner, showNameTooltip])

  const serviceNameLabel = useMemo(() => {
    if (!showNameTooltip) {
      return <p css={[title, hasTitleMaxWidth && titleMaxWidth, isPartner && partnerTitle]}>{name}</p>
    }

    return <TooltipOnHover css={titleTooltipContainer} text={name}>
      <p css={[title, hasTitleMaxWidth && titleMaxWidth, isPartner && partnerTitle]}>{name}</p>
    </TooltipOnHover>
  }, [hasTitleMaxWidth, isPartner, name, showNameTooltip])

  return (
    <div css={isFromActivitiesPage ? extendedActivityCard : activityCard} onClick={onNavMenuClick} {...other}>
      <SwatchImage
        css={[marginRight10, isFromActivitiesPage && activitiesPageCardImage]}
        size={30}
        image={image}
        alternativeText={name}
      />

      {!isFromActivitiesPage && (
        <div>
          {serviceNameLabel}
          {companyNameLabel}
        </div>
      )}

      {isFromActivitiesPage && (
        <div css={activitiesPageCardCompanyInfo}>
          <TooltipOnHover text={name}>
            <p css={[title, hasTitleMaxWidth && titleMaxWidth, activityName]}>{name}</p>
          </TooltipOnHover>
          {companyNameLabel}
        </div>
      )}
      {isFromActivitiesPage && (
        <div css={labelContainer}>
          <p css={link}>Ver experiência</p>
          <AngleThickRightIcon css={seeMoreIconPosition} />
        </div>
      )}
    </div>
  )
}
