/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, PaginationContainer, Label, PlusIcon, HeaderPage } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { cardButton, cardIcon, labelCardButton, marginBottom, marginTop } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useHistory } from 'react-router-dom'
import { ReservationsFilter } from './ReservationsFilter'
import { ReservationsTable } from '../ReservationsTable/ReservationsTable'
import { CardButton } from 'components/CardButton'
import { ReservationSelector } from './ReservationSelector'

const marginTop20 = marginTop(20)
const marginBottom60 = marginBottom(60)

export const ReservationsList = ({
  onFilterSubmit = identity,
  reservations = [],
  partnerList = [],
  total = 0,
  onPagination: emitPaginationEvent = identity,
  pagesQuantity = 1,
  loading
}) => {
  const history = useHistory()
  const goToDashboard = () => history.push('/')

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Reservas' />

      <ReservationSelector subdomain='bookings'>
        <CardButton css={cardButton}>
          <PlusIcon css={cardIcon} />
          <Label css={labelCardButton}>Nova reserva</Label>
        </CardButton>
      </ReservationSelector>

      <div />
      <Card css={[marginBottom60, marginTop20]}>
        <ReservationsFilter onFilterSubmit={onFilterSubmit} partnerList={partnerList} />
        <PaginationContainer total={total} pagesQuantity={pagesQuantity} onPagination={emitPaginationEvent} isLoading={loading}>
          <ReservationsTable reservations={reservations} loading={loading} />
        </PaginationContainer>
      </Card>
    </>
  )
}
