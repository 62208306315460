import { date, string, ref } from 'yup'

export const periodsSchema = {
  name: string().required('O nome do periodo é obrigatório'),
  startDate: date()
    .max(ref('endDate'), 'A data inicial deve ser menor ou igual a data final')
    .required('A data inicial é obrigatória'),
  endDate: date()
    .min(ref('startDate'), 'A data final deve ser maior ou igual a data inicial')
    .required('A data final é obrigatória')
}
