/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button, Dialog, InputFormGroup, MaskedInput, useToast } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { ReservationService } from 'services/Reservations/Service'

const formCss = css`
  margin: 20px 0;
`

const numberMask = v => v?.replace(/\D/g, '')

export const EditFiscalDuty = ({ reservationId, fiscalDuty, onClose, onUpdate }) => {
  const { add: addToast } = useToast()
  const [inputValue, setInputValue] = useState(fiscalDuty)

  useEffect(() => {
    setInputValue(fiscalDuty)
  }, [fiscalDuty])

  const handleUpdate = useCallback(() => {
    ReservationService.addFiscalDuty(reservationId, inputValue).then(() => {
      onUpdate()
      onClose()
      addToast('Nota fiscal atualizada com sucesso', 'success')
    }).catch(() => {
      addToast('Erro ao atualizar nota fiscal', 'error')
    })
  }, [addToast, inputValue, onClose, onUpdate, reservationId])

  if (!reservationId) return null

  return (
    <Dialog isVisible title='Editar nota fiscal' onClose={onClose}>
      <InputFormGroup label='Número da nota fiscal' customCss={[formCss]}>
        <MaskedInput
          onChange={setInputValue}
          placeholder='Digite o número'
          value={inputValue}
          formatterFunction={numberMask}
        />
      </InputFormGroup>
      <Button type='button' disabled={!inputValue} onClick={handleUpdate}>Salvar</Button>
    </Dialog>
  )
}
