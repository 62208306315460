/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useDebounce } from '@bonitour/app-functions'
import { Card, Column, flexCenteredSpaceBetweenRow, flexRow, GhostButton, H1, InputWithSuffix, LoadingAnimation, Row, SearchIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { loadingContainer, marginBottom } from 'assets/styles/global'
import { ActivityCard } from 'containers/Activity/ActivityCard'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { OfflineExperiences } from './OfflineExperiences'
import { searchIcon, listContainer, activitiesContainer, partnerActivityCard, search, activityCard } from './Activities.style'

const marginBottom0 = marginBottom(0)
const marginBottom20 = marginBottom(20)

export const Activities = ({ companyActivities = [], partnerActivities = [], loading = false, title = '', isFromActivitiesPage = false, isPartner = false, isOffline = false }) => {
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)

  const hasPartnerActivities = Boolean(partnerActivities && partnerActivities.length)
  const hasCompanyActivities = Boolean(companyActivities && companyActivities.length)

  const { push } = useHistory()
  const goToDashboard = () => push('/dashboard')
  const inputPlaceholder = `Busca${isFromActivitiesPage ? `r em ${isPartner ? 'experiências de parceiros' : 'minhas experiências'}` : ''}`

  const filterActivities = useCallback((activities) => {
    if (queryWithDebounce) {
      return activities.filter(({ name, companyName }) => {
        const activityQueryLowercase = queryWithDebounce.toLowerCase()
        const activityLowecase = name.toLowerCase()
        const companyNameLowecase = companyName.toLowerCase()
        const hasQueryInName = activityLowecase.includes(activityQueryLowercase)
        const hasQueryInCompanyName = companyNameLowecase.includes(activityQueryLowercase)
        return hasQueryInName || hasQueryInCompanyName
      })
    }
    return activities
  }, [queryWithDebounce])

  const filteredActivities = useMemo(
    () => {
      const filteredActivities = hasCompanyActivities ? filterActivities(companyActivities) : filterActivities(partnerActivities)
      return filteredActivities
    }
    , [companyActivities, filterActivities, hasCompanyActivities, partnerActivities])

  return (
    <>
      {!isFromActivitiesPage && (
        <div css={[flexRow, marginBottom20, flexCenteredSpaceBetweenRow]}>
          <H1 css={marginBottom0}>{title}</H1>
          <GhostButton onClick={goToDashboard}>Voltar</GhostButton>
        </div>
      )}
      {loading
        ? <LoadingAnimation css={loadingContainer} />
        : (
          <>
            {hasPartnerActivities && (
              <>
                <div>
                  <InputWithSuffix css={search} value={query} onChange={setQuery} placeholder={inputPlaceholder}>
                    <SearchIcon css={searchIcon} />
                  </InputWithSuffix>
                </div>
                <div css={listContainer}>
                  <Row>
                    <Column phone={12} css={activitiesContainer}>
                      {filteredActivities.map(activity => (
                        <Card css={isFromActivitiesPage ? activityCard : partnerActivityCard} key={`dashboard-activity-${activity.id}`}>
                          <ActivityCard activity={activity} isFromActivitiesPage={isFromActivitiesPage} isPartner showNameTooltip />
                        </Card>
                      ))}
                    </Column>
                  </Row>
                </div>
              </>
            )}
            {hasCompanyActivities && (
              <>
                <div>
                  <InputWithSuffix css={search} value={query} onChange={setQuery} placeholder={inputPlaceholder}>
                    <SearchIcon css={searchIcon} />
                  </InputWithSuffix>
                </div>
                <div css={listContainer}>
                  <Row>
                    <Column phone={12} css={activitiesContainer}>
                      {filteredActivities.map(activity => (
                        <Card css={isFromActivitiesPage ? activityCard : partnerActivityCard} key={`dashboard-activity-${activity.id}`}>
                          <ActivityCard activity={activity} isFromActivitiesPage={isFromActivitiesPage} />
                        </Card>
                      ))}
                    </Column>
                  </Row>
                </div>
              </>
            )}
            {isOffline && (
              <OfflineExperiences isFromActivitiesPage={isFromActivitiesPage} />
            )}
          </>
        )}
    </>
  )
}
