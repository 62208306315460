import { css } from '@emotion/core'

export const container = css`
  display: flex;
  margin: 0 0 25px 0;
`
export const link = css`
  text-decoration: none;
`

export const tabPaneContainer = css`
  width: 100%;
  padding: 20px 0 0;
`

export const underlineNavContainer = css`
  padding: 0;
`
