/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import { colors, flexHorizontallyCentered } from '@bonitour/components'

export const container = css`
  display: flex;
  margin: 0 0 30px 0;
`

export const cardIcon = css`
  font-size: 20px;
  padding: 4px 5px;
  border-radius: var(--round-element-radius, 50%);
`

export const addCardIcon = css`
  ${cardIcon}
  font-size: 18px;
  color: ${colors.white};
  background-color: ${colors.primary};
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  width: 100%;
`

export const labelSubCardItem = css`
  margin: 5px;
  color: ${colors.gray1};
  font-size: initial;
  font-weight: initial;
  cursor: pointer;
  font-size: 15px;
`

export const subCardItem = css`
  width: calc(100% + 15px);
  margin: 8px 0;
  text-align: left;
`

export const addLabelCardButton = css`
  ${labelCardButton};
  color: ${colors.primary};
`

export const floating = css`
  align-items: flex-end;
  position: fixed;
  bottom: 80px;
  right: 50px;
`

export const buttonContainer = css`
  margin-bottom: 10px;
  margin-right: 5px;
`

export const myActivitiesContainer = css`
  flex-wrap: wrap;
`

export const angleDownStyle = css`
  font-size: 14px;
`

export const linkColor = css`
  color: ${colors.gray1};
  padding: 5px;
  margin: 5px 0;
`

export const linkColorWithoutMargin = css`
  color: ${colors.gray1};
`

export const cardItems = css`
  min-width: 120px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
`

export const cardItemIcon = css`
  min-height: 28px;
`

export const activitiesLink = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  width: auto;
  min-width: 98px;
  padding: 10px;
`

export const cardButton = css`
  padding: 2px;
  margin: 5px;
  height: 100%;

  label {
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
  }
`

export const tagStyle = css`
  margin: 0 0 0 15px;
  padding: 4px;
  font-size: 13px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
`

export const height100percent = css`
  height: 100%;
`

export const dashboardShortcuts = css`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  grid-auto-rows: 1fr;
  gap: 16px 0;
  margin-bottom: 40px;
`

export const itemRowLink = css`
  ${flexHorizontallyCentered}
  text-align: left;
`
