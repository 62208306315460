/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, Column, InputWithSuffix, LoadingAnimation, Row, SearchIcon, Select } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { loadingContainer } from 'assets/styles/global'
import { ActivityCard } from 'containers/Activity/ActivityCard'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { offlineExperienceCategoriesOptions } from 'constants/offlineExperiences'
import { useOfflineExperiences } from './hooks/useOfflineExperiences'
import { searchIcon, listContainer, activitiesContainer, partnerActivityCard, offlineSearch, activityCard } from './Activities.style'

export const offlineExperienceCategoriesFilterOptions = [
  { label: 'Todas', value: '' },
  ...offlineExperienceCategoriesOptions
]

export const OfflineExperiences = ({ isFromActivitiesPage }) => {
  const inputPlaceholder = `Busca${isFromActivitiesPage ? 'r em experiências offline' : ''}`

  const {
    offlineExperiences,
    loadNextPage,
    isLoading,
    category,
    setCategory,
    query,
    setQuery
  } = useOfflineExperiences()

  return (
    <>
      <div>
        <InputWithSuffix css={offlineSearch} value={query} onChange={setQuery} placeholder={inputPlaceholder}>
          <SearchIcon css={searchIcon} />
        </InputWithSuffix>
        <Select
          placeholder='Categorias'
          options={offlineExperienceCategoriesFilterOptions}
          value={category}
          onChange={setCategory}
        />
      </div>
      <div css={listContainer}>
        <Row>
          <Column phone={12} css={activitiesContainer}>
            {offlineExperiences.map(activity => (
              <Card css={isFromActivitiesPage ? activityCard : partnerActivityCard} key={`dashboard-offline-experience-${activity.id}`}>
                <ActivityCard activity={activity} isFromActivitiesPage={isFromActivitiesPage} />
              </Card>
            ))}
          </Column>
        </Row>
      </div>
      <InfiniteScroll nextPage={loadNextPage} loading={isLoading} />
      {isLoading ? <LoadingAnimation css={loadingContainer} /> : null}
    </>
  )
}
