/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityVacancyForm } from 'containers/ActivityVacancyForm/ActivityVacancyForm'
import { useToast } from '@bonitour/components'
import { ActivityVacancyService } from 'services/Vacancy/Service'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { deepGetOrElse } from 'deep-getter'
import { useActivity } from 'contexts/Activity'

export const EditVacancy = () => {
  const [activityVacancy, setActivityVacancy] = useState({})
  const { add: addToast } = useToast()
  const { id: activityId } = useActivity()
  const { vacancyId } = useParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const returnToEditHref = useMemo(() => {
    if (pathname.includes('/transport')) {
      return `/transport/${activityId}/edit?accordion=4`
    }

    return `/activity/${activityId}/edit?accordion=4`
  }, [activityId, pathname])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  useEffect(() => {
    ActivityVacancyService.getById(vacancyId).then(setActivityVacancy).catch(() => {
      returnToEdit()
      addToast('Vaga não encontrada')
    })
  }, [addToast, returnToEdit, vacancyId])

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSuccess = async data => {
    try {
      await ActivityVacancyService.update(activityId, vacancyId, data)
      returnToEdit()
      addToast('Vaga atualizada', 'success')
    } catch (error) {
      addToast('Ocorreu um erro na edição da vaga')
    }
  }

  const title = deepGetOrElse(activityVacancy, 'activityVacancy.vacancyName', 'Vaga')

  return (
    <ActivityVacancyForm
      title={title}
      activityVacancy={activityVacancy}
      onSuccess={onSuccess}
      onBackClick={returnToEdit}
      onValidationError={onValidationError}
    />
  )
}
