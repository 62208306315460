import { useEffect, useState } from 'react'
import { TransportService } from 'services/Transport/Service'

export const useTransport = (activity) => {
  const [transport, setTransport] = useState(null)
  useEffect(() => {
    const isTransport = activity?.id && (activity?.type === 'Experiences::Transport')
    if (isTransport) {
      TransportService.get(activity.id).then(setTransport)
    } else {
      setTransport(null)
    }
  }, [activity])

  return transport
}
