/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AngleDownIcon, Dialog } from '@bonitour/components'
import { activityTitle, companyTitle, activitySelector, activityTitleIcon, activityPopoverWrapper, selectorStyle, dialogContainer } from './Selector.style'
import { ActivityListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { useActivity } from 'contexts/Activity'
import { useState } from 'react'

export const ActivitySelector = ({ subdomain, email, ...other }) => {
  const { activity: { name: activityName, companyName } = {} } = useActivity()
  const [isSelectorVisible, setVisibility] = useState(false)
  const onSelectorOpen = () => setVisibility(true)
  const onSelectorClose = () => setVisibility(false)

  return (
    <>
      <div css={activitySelector} onClick={onSelectorOpen} {...other}>
        <span css={companyTitle}>{companyName}</span>
        <span css={activityTitle}>
          {activityName}
          <div css={activityPopoverWrapper}>
            <AngleDownIcon css={activityTitleIcon} />
          </div>
        </span>
      </div>

      <Dialog customContainercss={[dialogContainer]} title='Selecione uma experiência' isVisible={isSelectorVisible} onClose={onSelectorClose}>
        <ActivityListSelector css={selectorStyle} onNavigation={onSelectorClose} subdomain={subdomain} showCreateButton={false} email={email} />
      </Dialog>
    </>
  )
}
