/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useActivity } from 'contexts/Activity'
import { Activities } from '../List/Activities/Activities'

export const CompanyActivities = () => {
  const { companyActivities = [], loading } = useActivity()

  return (
    <Activities loading={loading} companyActivities={companyActivities} title='Minhas Experiências' />
  )
}
