/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { Dialog } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { selectorContainer, dialogContainer, selectorStyle } from './ReservationSelector.style'

export const ReservationSelector = ({ subdomain, children, emitOptionSelected = identity, email = '', customCss = [] }) => {
  const [isSelectorVisible, setVisibility] = useState(false)
  const onSelectorOpen = () => setVisibility(true)
  const onSelectorClose = () => setVisibility(false)

  const onOptionClick = () => {
    onSelectorClose()
    emitOptionSelected()
  }

  return (
    <>
      <div css={[selectorContainer, ...customCss]}>
        <div onClick={onSelectorOpen}>{children}</div>
      </div>

      <Dialog customContainercss={[dialogContainer]} title='Selecione uma experiência' isVisible={isSelectorVisible} onClose={onSelectorClose}>
        <ActivityListSelector css={selectorStyle} onNavigation={onOptionClick} subdomain={subdomain} showCreateButton={false} withOfflineExperiences email={email} />
      </Dialog>
    </>
  )
}
