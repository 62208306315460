import { formatISOTime } from 'utils/time'

const extrasMap = {
  Pagamento: { isPayment: true },
  Estorno: { isRefund: true }
}

export const TransactionParser = {
  list ({ transactions = [], total_amount: total, total_amount_due: due, total_amount_paid: paid, total_cancellation_balance: credit }) {
    return {
      transactions: transactions.map(TransactionParser.listItem),
      amount: {
        total: Number(total),
        due: Number(due),
        paid: Number(paid),
        credit: Number(credit)
      }
    }
  },
  listItem (transaction) {
    const {
      documentation,
      id,
      installments = '',
      transaction_date: isoDate,
      expiration_date: isoExpirationDate,
      transaction_method: method,
      state,
      transaction_type: type,
      value,
      destination_company_id: companyDestinationId,
      destination_company_name: companyDestinationName,
      liable_company_id: responsiblePaymentCompanyId,
      liable_company_name: responsiblePaymentCompanyName,
      custom_vendor_id: customVendorId,
      custom_vendor_name: customVendorName,
      item_type: itemType
    } = transaction || {}

    const extras = extrasMap[type]
    const date = formatISOTime(isoDate, 'YYYY-MM-DD', false)
    const hour = formatISOTime(isoDate, 'HH:mm', false)
    const expirationDate = formatISOTime(isoExpirationDate, 'YYYY-MM-DD')
    const expirationHour = formatISOTime(isoExpirationDate, 'HH:mm')

    return {
      type,
      id,
      value,
      method,
      installments,
      autDoc: documentation,
      state,
      hour,
      date,
      expirationDate,
      expirationHour,
      companyDestinationId,
      companyDestinationName,
      responsiblePaymentCompanyId,
      responsiblePaymentCompanyName,
      customVendorId,
      customVendorName,
      itemType,
      ...extras
    }
  },
  paymentLinks ({ payment_links: paymentLinks = [] }) {
    return paymentLinks.map(TransactionParser.paymentLinkItem)
  },
  paymentLinkItem (paymentLink) {
    const {
      id,
      value,
      state,
      expires_in: isoExpirationDate,
      created_at: isoDate,
      custom_vendor_id: customVendorId,
      liable_company_id: liableCompanyId,
      liable_company_name: liableCompanyName,
      liable_user_name: liableUserName,
      custom_vendor_name: customVendorName,
      installments,
      aut = '',
      doc = '',
      payment_method: method,
      gateway = '',
      reservation_payment_id: reservationPaymentId
    } = paymentLink || {}

    const date = formatISOTime(isoDate, 'YYYY-MM-DD', false)
    const hour = formatISOTime(isoDate, 'HH:mm', false)
    const expirationDate = formatISOTime(isoExpirationDate, 'YYYY-MM-DD')
    const expirationHour = formatISOTime(isoExpirationDate, 'HH:mm')

    return {
      id,
      value,
      state,
      liableCompanyId,
      date,
      hour,
      expirationDate,
      expirationHour,
      customVendorId,
      liableCompanyName,
      customVendorName,
      liableUserName,
      installments,
      gateway,
      aut,
      doc,
      method,
      reservationPaymentId
    }
  },
  payments ({ reservation_payments: payments }) {
    return payments.map(TransactionParser.paymentItem)
  },
  paymentItem (payment) {
    const {
      id,
      value,
      payment_method: method,
      state,
      payment_date: isoDate,
      installments,
      aut = '',
      doc = '',
      liable_user_name,
      registered_by_user_name: liableUserName = liable_user_name,
      custom_vendor_name: customVendorName
    } = payment || {}
    return {
      id,
      value,
      method,
      state,
      aut,
      doc,
      installments,
      date: formatISOTime(isoDate, 'YYYY-MM-DD', false),
      hour: formatISOTime(isoDate, 'HH:mm', false),
      liableUserName,
      customVendorName
    }
  },
  refunds ({ reservation_refunds: refunds }) {
    return refunds.map(TransactionParser.refundItem)
  },
  refundItem (refund) {
    const {
      id,
      value,
      refund_method: method,
      refund_date: isoDate,
      aut = '',
      doc = '',
      liable_user_name,
      registered_by_user_name: liableUserName = liable_user_name,
      installments = 0
    } = refund || {}

    return {
      id,
      value,
      method,
      aut,
      doc,
      date: formatISOTime(isoDate, 'YYYY-MM-DD', false),
      hour: formatISOTime(isoDate, 'HH:mm', false),
      liableUserName,
      installments
    }
  }
}
