/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useActivity } from 'contexts/Activity'
import { Activities } from '../List/Activities/Activities'

export const PartnersActivities = () => {
  const { partnerActivities = [], loading } = useActivity()

  return (
    <Activities loading={loading} partnerActivities={partnerActivities} title='Experiências de parceiros' />
  )
}
