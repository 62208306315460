import React, { createContext, useContext, useMemo, useCallback, useEffect, useState } from 'react'
import { ActivityStore } from 'core/store/Activity'
import { useSimpleStorage } from 'hooks/useSimpleStorage'
import { ActivityService } from 'services/Activity/Service'
import { useParams } from 'react-router-dom'
import { useCompany } from './Company'
import { ReservationMapService } from 'services/ReservationMap/Service'
import { formatISOTime } from 'utils/time'

export const ActivityContext = createContext({})

export const useActivity = () => {
  const { activityId } = useParams()
  const methods = useContext(ActivityContext)
  const { id, setId } = methods

  useEffect(() => {
    if (activityId && activityId !== id) {
      setId(activityId)
    }
  }, [activityId, setId, id])

  return methods
}

let wasActivityPreviouslyRequested = false

const sortActivities = (activities) =>
  activities.sort((a, b) => a.companyName.localeCompare(b.companyName) || a.name.localeCompare(b.name))

const isOffline = ({ type }) => type === 'Experiences::OfflineExperience'

export const ActivityProvider = ({ children }) => {
  const { id: companyId } = useCompany()
  const [id, setId] = useSimpleStorage(ActivityStore.getId, ActivityStore.setId)
  const [list, setList] = useSimpleStorage(ActivityStore.getList, ActivityStore.setList)
  const [partners, setPartners] = useState([])
  const [activity, setActivity] = useSimpleStorage(ActivityStore.getActivity, ActivityStore.setActivity)
  const [loading, setLoading] = useState(false)
  const enabledActivities = true

  const updateList = useCallback(() => {
    setLoading(true)
    ActivityService.list(enabledActivities)
      .then(sortActivities)
      .then(setList)
      .finally(() => setLoading(false))
  }, [setList, enabledActivities])

  const companyActivities = useMemo(
    () => list.filter(({ isPartner, type }) => !isOffline({ type }) && !isPartner),
    [list]
  )
  const partnerActivities = useMemo(
    () => list.filter(({ isPartner, type }) => !isOffline({ type }) && isPartner),
    [list]
  )
  const unparsedActivities = useMemo(
    () => companyActivities.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )

  const companiesOptions = useMemo(
    () =>
      partners.map(({ companyId, companyName }) => ({
        value: companyId,
        label: companyName
      })),
    [partners]
  )

  const parseActivities = useCallback(
    (selectedCompanyId) => {
      if (!selectedCompanyId) return []

      const activitiesFiltered = unparsedActivities.filter(({ companyId }) => companyId === selectedCompanyId)

      return activitiesFiltered.map(({ id, name, companyName }) => ({
        value: id,
        label: `${name} - ${companyName}`
      }))
    },
    [unparsedActivities]
  )

  const getActivityById = useCallback((searchId) => list.find(({ id: activityId }) => activityId === searchId), [list])

  const getActivitiesByPartnerId = useCallback(
    (searchId) => list.filter(({ companyId }) => companyId === searchId),
    [list]
  )

  useEffect(() => {
    const selectedActivity = list.find(({ id: activityId }) => activityId === id)
    if (selectedActivity) {
      setActivity(selectedActivity)
    } else {
      if (!wasActivityPreviouslyRequested) {
        wasActivityPreviouslyRequested = true
        updateList()
      } else {
        wasActivityPreviouslyRequested = false
        setActivity({})
      }
    }
  }, [id, list, updateList, setActivity])

  const fetchPartners = useCallback(() => {
    return ActivityService.getPartners(companyId).then(setPartners)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchPartners()
  }, [fetchPartners])

  const fetchHourly = useCallback((serviceId, date, companyId) => {
    const DateFormatted = formatISOTime(date.toISOString(), 'YYYY-MM-DD HH:mm:ss')
    return ReservationMapService.getHourlyOptions({
      serviceId,
      companyId,
      startDate: DateFormatted,
      endDate: DateFormatted
    })
  }, [])

  const contextData = useMemo(
    () => ({
      getActivityById,
      getActivitiesByPartnerId,
      fetchPartners,
      partners,
      id,
      list,
      setId,
      activity,
      companyActivities,
      partnerActivities,
      loading,
      updateList,
      unparsedActivities,
      parseActivities,
      fetchHourly,
      companiesOptions
    }),
    [
      id,
      list,
      setId,
      activity,
      updateList,
      companyActivities,
      partnerActivities,
      loading,
      getActivityById,
      getActivitiesByPartnerId,
      partners,
      fetchPartners,
      parseActivities,
      unparsedActivities,
      fetchHourly,
      companiesOptions
    ]
  )

  return <ActivityContext.Provider value={contextData}>{children}</ActivityContext.Provider>
}
