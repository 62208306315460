/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PartnershipInvite } from 'containers/PartnershipGroup/Partnership/PartnershipInvite/PartnershipInvite'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { InvitesService } from 'services/Invites/Service'
import { useToast } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'

export const PartnershipInvitation = () => {
  const { inviteId } = useParams()
  const { add: addToast } = useToast()
  const history = useHistory()

  const [inviteInformation, setInviteInformation] = useState({})
  const { updateList } = useActivity()

  const backToProviders = useCallback(() => history.push('/providers'), [history])
  const respondInvite = useCallback((response = true) => InvitesService.respond(inviteId, response), [inviteId])

  const onAcceptance = async () => {
    try {
      await respondInvite()
      addToast('Convite aceito', 'success')
      updateList()
      backToProviders()
    } catch (error) {
      addToast('Ocorreu um erro com a comunicação com o servidor')
    }
  }

  const onRefusal = async () => {
    try {
      await respondInvite(false)
      addToast('Convite rejeitado', 'success')
      updateList()
      backToProviders()
    } catch (error) {
      addToast('Ocorreu um erro com a comunicação com o servidor')
    }
  }

  useEffect(() => {
    InvitesService.get(inviteId).then(invite => {
      if (invite.answered) {
        addToast('Convite já respondido', 'warning')
        backToProviders()
      }
      setInviteInformation(invite)
    })
  }, [inviteId, backToProviders, addToast])

  return (
    <PartnershipInvite
      inviteInformation={inviteInformation}
      onAcceptance={onAcceptance}
      onRefusal={onRefusal}
      onBackClick={backToProviders}
    />)
}
