/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { flexHorizontallyCentered, DatePicker, TrashIcon, flexRowCentered, HorizontalInputFormGroup, Input } from '@bonitour/components'
import { width, clickable, alignTrashIconAtEnd } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import moment from 'moment'
import { rowMargin, flexColumn, iconSize, marginTop10, sameLineInput, width150, width220 } from './PeriodRow.style'

const compareDates = (startDate, endDate) => moment(endDate).isSameOrAfter(startDate)

export function PeriodRow ({
  vacancyPeriod = { name: '' },
  placeholder = '',
  errors = {},
  index = 0,
  onBlur: emitBlurEvent = identity,
  onChange: emitChangeEvent = identity,
  onDelete: emitDeleteEvent = identity
}) {
  const { name, startDate, endDate } = vacancyPeriod

  const startDateChange = newStartDate => {
    if (!compareDates(newStartDate, endDate)) {
      emitChangeEvent(`periodList.${index}.endDate`)(newStartDate)
    }
    emitChangeEvent(`periodList.${index}.startDate`)(newStartDate)
  }

  return (
    <div css={[flexRowCentered, rowMargin]}>
      <div css={[flexHorizontallyCentered, width(95), flexColumn]}>
        <p css={sameLineInput}>Nome do período será</p>
        <HorizontalInputFormGroup css={width220} errorMessage={errors.name}>
          <Input
            type='text'
            value={name}
            error={errors.name}
            placeholder={placeholder}
            onChange={emitChangeEvent(`periodList.${index}.name`)}
            onBlur={emitBlurEvent(`periodList.${index}.name`)}
            css={marginTop10}
          />
        </HorizontalInputFormGroup>
        <p css={[sameLineInput, marginTop10]}>O início será em</p>
        <HorizontalInputFormGroup css={[width150, marginTop10]} errorMessage={errors.startDate}>
          <DatePicker
            value={startDate}
            error={Boolean(errors.startDate)}
            onChange={startDateChange}
            onBlur={emitBlurEvent(`periodList.${index}.startDate`)}
          />
        </HorizontalInputFormGroup>

        <p css={[sameLineInput, marginTop10]}>e o fim em</p>
        <HorizontalInputFormGroup css={[width150, marginTop10]} errorMessage={errors.endDate}>
          <DatePicker
            value={endDate}
            error={Boolean(errors.endDate)}
            onChange={emitChangeEvent(`periodList.${index}.endDate`)}
            onBlur={emitBlurEvent(`periodList.${index}.endDate`)}
          />
        </HorizontalInputFormGroup>
      </div>
      <div css={[width(5), alignTrashIconAtEnd, flexHorizontallyCentered]}>
        {index !== 0 && <TrashIcon css={[iconSize, clickable]} onClick={emitDeleteEvent} />}
      </div>
    </div>
  )
}
