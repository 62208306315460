/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableContainer, TableBody, scrollBar, flexColumnCentered } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { VacancyTableHeader } from 'domains/Booking/Map/Table/Header/VacancyTableHeader'
import { VacancyTableRow } from 'domains/Booking/Map/Table/Row/VacancyTableRow'
import { marginBottom } from 'assets/styles/global'
import { useMemo } from 'react'

const tableBody = css`
  position: relative;
  padding: 10px 0;
  min-height: 165px;
  max-height: calc(100vh - 440px);
  overflow: auto;
  ${scrollBar(5)};
`

const block = css`
  display: block;
`

const scrollTable = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`

const marginBottom20 = marginBottom(20)

const VacancyTable = ({
  date = new Date(),
  isExpandable = true,
  dateColumns = [],
  tablePrices = {},
  tableData = {},
  tableDetailedData = {},
  isLoading = false,
  isActivityOwner = false,
  VacancySummaryPopoverContent,
  currentExpandedRowLabel,
  onExpand: emitExpandEvent = identity,
  onExceptionalDayEditClick = identity
}) => {
  const isExpanded = hour => hour === currentExpandedRowLabel
  const hasData = useMemo(() => Object.keys(tableData).length, [tableData])
  return (
    <TableContainer css={isLoading && flexColumnCentered}>
      <VacancyTableHeader
        selectedDate={date}
        dateColumns={dateColumns}
        tablePrices={tablePrices}
        css={[scrollTable, isLoading && marginBottom20]}
        onExceptionalDayEditClick={onExceptionalDayEditClick}
        isActivityOwner={isActivityOwner}
      />
      <TableBody css={[tableBody, hasData && block]} loading={isLoading}>
        {Object.entries(tableData).map(([hour, datesData]) => (
          <VacancyTableRow
            css={scrollTable}
            isExpandable={isExpandable}
            key={`date-row-hour-${hour}`}
            VacancySummaryPopoverContent={VacancySummaryPopoverContent}
            rowHour={hour}
            isExpanded={isExpanded(hour)}
            onExpand={emitExpandEvent}
            datesData={datesData}
            tableDetailedData={tableDetailedData}
            dateColumns={dateColumns}
            onExceptionalDayEditClick={onExceptionalDayEditClick}
            tablePrices={tablePrices}
          />
        ))}
      </TableBody>
    </TableContainer>
  )
}

export default VacancyTable
