/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { MonthLabel } from './VacancyTableMonthLabel'
import { VacancyTableDateIndicator } from './VacancyTableDateIndicator'
import { VacancyTablePrice } from './VacancyTablePrice'

import 'moment/locale/pt-br'
import moment from 'moment'
import { identity } from '@bonitour/common-functions'

moment.locale('pt-BR')

const month = css`
  padding: 5px;
  width: 122px;
`

const day = css`
  padding: 5px 5px 10px 5px;
  width: 110px;
  text-align: center;
`

export const VacancyTableHeader = ({
  selectedDate = new Date(),
  isActivityOwner = false,
  dateColumns = [],
  tablePrices = {},
  onExceptionalDayEditClick = identity,
  ...other
}) => {
  const monthLabel = moment(selectedDate).format('MMMM/Y')

  return (
    <TableHeader {...other}>
      <TableHeaderRow>
        <TableHeaderCell css={month}>
          <MonthLabel monthContent={monthLabel} />
        </TableHeaderCell>
        {
          dateColumns.map((date, index) => (
            <TableHeaderCell css={day} key={`date-column-${index}`}>
              <VacancyTableDateIndicator date={date} onExceptionalDayEditClick={onExceptionalDayEditClick} isActivityOwner={isActivityOwner} />
              <VacancyTablePrice dayPrices={tablePrices[date]} />
            </TableHeaderCell>
          ))
        }
      </TableHeaderRow>
    </TableHeader>
  )
}
