import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const partnerTitle = css`
  font-size: 12px;
  color: ${colors.gray4};
  font-weight: bolder;
`

export const partnerSubtitle = css`
  font-size: 11px;
  color: ${colors.gray6};
`

export const activityCard = css`
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  color: ${colors.gray3};
  margin: 10px 0;
  align-items: center;
  padding: 0px 5px;

  &.active{
    background-color: ${colors.gray12};
  }
`

export const extendedActivityCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: space-evenly;
  padding: 20px 0;
`

export const activitiesPageCardImage = css`
  width: 79px;
  height: 79px;
  margin: 0;
`

export const activitiesPageCardSubtitle = css`
  color: ${colors.gray6};
  height: 21px;
`

export const activitiesPageCardCompanyInfo = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: center;
`

export const seeMoreIconPosition = css`
  transform: translate3d(5px,1px,10px);
  color: ${colors.gray4};
`

export const link = css`
  color: ${colors.gray4};
`

export const activityName = css`
  color: ${colors.gray3};  
`

export const title = css`
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;  
  white-space: nowrap;
  line-height: 1.5;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    max-width: 95px;
  }
`

export const titleMaxWidth = css`
  max-width: 120px;
`

export const labelContainer = css`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    display: none;
  }
`

export const titleTooltipContainer = css`
  justify-content: start;
`
