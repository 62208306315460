import { useDebounce } from '@bonitour/app-functions'
import { useCallback, useState, useEffect } from 'react'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { perPage } from 'constants/offlineExperiences'

export const useOfflineExperiences = () => {
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 1000)

  const [offlineExperiences, setOfflineExperiences] = useState([])
  const [meta, setMeta] = useState({ currentPage: 0 })
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [category, setCategory] = useState(undefined)
  const [offlineProviderId, setOfflineProviderId] = useState(undefined)

  useEffect(() => {
    if (page > meta.currentPage) {
      setIsLoading(true)
      const pagination = {
        page,
        per_page: perPage
      }
      const searchParams = {
        title: queryWithDebounce?.length > 2 ? queryWithDebounce : undefined,
        enabled: true,
        offline_provider_id: offlineProviderId,
        category
      }

      OfflineExperienceService
        .list({ searchParams, pagination })
        .then(({ experiences, meta }) => {
          setOfflineExperiences(value => [...value, ...experiences])
          setMeta(meta)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [page, meta.currentPage, queryWithDebounce, category, offlineProviderId])

  useEffect(() => {
    setPage(1)
    setMeta({ currentPage: 0 })
    setOfflineExperiences([])
  }, [queryWithDebounce, category, offlineProviderId])

  const loadNextPage = useCallback(() => {
    if (!isLoading && page < meta.totalPages) {
      setPage(page + 1)
    }
  }, [isLoading, page, meta.totalPages])

  return {
    offlineExperiences,
    offlineExperienceOptions: offlineExperiences?.map(({ name, id }) => ({ label: name, value: id })),
    loadNextPage,
    isLoading,
    meta,
    category,
    setCategory,
    query,
    setQuery,
    offlineProviderId,
    setOfflineProviderId
  }
}
