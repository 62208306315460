/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { NavLink } from 'react-router-dom'
import { jsx, css } from '@emotion/core'
import { AngleThickRightIcon, Card, colors, Column, flexRow, P, Row, scrollBar, BREAK_POINTS } from '@bonitour/components'

import { ActivityCard } from 'containers/Activity/ActivityCard'
import { resetLink } from 'assets/styles/global'

const { primary, gray5 } = colors

const myActivities = css`
  font-size: 14px;
  color: ${gray5};
  margin-bottom: 10px;
`

const activityCard = css`
  width: 175px;
  min-width: 175px;
  padding: 10px 0 5px 10px;
  margin: 0 20px 20px 0;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 145px;
    min-width: 145px;
    margin: 4px;
    padding: 2px;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    margin: 4px;
    padding: 2px;
  }
`

const activitiesContainer = css`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  ${scrollBar(0)};
`

const seeMoreIconPosition = css`
  transform: translate3d(5px,-7px,10px);
  color: ${primary};
`

const seeMoreText = css`
  color: ${primary};
`

const container = css`
  display: flex;

  @media (max-width: ${BREAK_POINTS.tablet}) {
    overflow-x: auto;
    ${scrollBar(0)};
  }
`

export const ActivityList = ({ activities = [], partnerActivities = [] }) => {
  const hasActivities = Boolean(activities && activities.length)
  const hasPartnerActivities = Boolean(partnerActivities && partnerActivities.length)
  const hasTooManyCompanyActivities = Boolean(activities && activities.length > 6)
  const hasTooManyPartnersActivities = Boolean(partnerActivities && partnerActivities.length > 6)

  return (
    <>
      <Row>
        <Column phone={12} css={activitiesContainer}>
          {hasActivities && <p css={myActivities}>Minhas experiências</p>}
          {hasTooManyCompanyActivities &&
            <NavLink to='/activities?selectedNav=myExperiences' css={[flexRow, resetLink]}>
              <P css={seeMoreText}>Ver todas</P>
              <AngleThickRightIcon css={seeMoreIconPosition} />
            </NavLink>}
        </Column>

        <Column phone={12}>
          <div css={container}>
            {activities.slice(0, 6).map(activity => (
              <Card css={activityCard} key={`dashboard-activity-${activity.id}`}>
                <ActivityCard activity={activity} showNameTooltip={true} />
              </Card>
            ))}
          </div>
        </Column>
      </Row>

      <Row>
        <Column phone={12} css={activitiesContainer}>
          {hasPartnerActivities && <p css={myActivities}>Experiências de parceiros</p>}
          {hasTooManyPartnersActivities &&
            <NavLink to='/activities?selectedNav=partnerExperiences' css={[flexRow, resetLink]}>
              <P css={seeMoreText}>Ver todas</P>
              <AngleThickRightIcon css={seeMoreIconPosition} />
            </NavLink>}
        </Column>

        <Column phone={12}>
          <div css={container}>
            {partnerActivities.slice(0, 6).map(activity => (
              <Card css={activityCard} key={`dashboard-activity-${activity.id}`}>
                <ActivityCard activity={activity} showNameTooltip={true} />
              </Card>
            ))}
          </div>
        </Column>
      </Row>
    </>
  )
}
