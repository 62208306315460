/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useCallback } from 'react'
import { CompanyDashboard as CompanyDashboardContainer } from 'containers/Company/Dashboard/Dashboard'
import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { CompanyService } from 'core/services/Company'

export const CompanyDashboard = () => {
  const { companyActivities = [], partnerActivities = [], loading } = useActivity()
  const { company = {} } = useCompany()
  const [reservationsInfo, setReservationsInfo] = useState([])

  const reservationsRequest = useCallback((currentPage, pageLimit) => {
    CompanyService.reservations(currentPage, pageLimit).then(setReservationsInfo)
  }, [])

  return (
    <CompanyDashboardContainer
      activities={companyActivities}
      partnerActivities={partnerActivities}
      isActivityLoading={loading}
      company={company}
      reservations={reservationsInfo.reservationsList}
      pagesQuantity={reservationsInfo.totalPages}
      totalReservations={reservationsInfo.total}
      onReservationPagination={reservationsRequest}
    />
  )
}
