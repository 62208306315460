/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableBodyCell, TrashIcon, EyeIcon, TooltipOnHover, flex, PencilIcon, RefoundIcon, CopyIcon, SendIcon, EllipsisIcon, Dialog, Label } from '@bonitour/components'
import { iconClick, options, inlineOptions, iconLabel, optionsIcon, optionsContainer, dialog } from './TableRow.style'
import { margin } from 'assets/styles/global'
import { useCompany } from 'contexts/Company'
import { useCallback, useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { useFinanceActions } from 'domains/Reservation/Transactions/hooks/useFinanceActions'
import { GATEWAYS_MAP } from 'constants/acquirers'

const marginHorizontal8 = margin(0, 8, 0, 8)

const ReservationPaymentOptions = ({
  label,
  card,
  isPaidPaymentLink,
  isRefund,
  onShow,
  onPaymentLinkShow,
  onRefundLinkShow,
  onEdit,
  onDelete,
  onRefundSplit,
  onRefoundInline,
  onCopy,
  onRedirect,
  isPayCreditPayment,
  isPayPixPayment,
  isCreditPayment,
  isDebitCreditPayment,
  isOptionEnabled,
  isResponsiblePaymentCompany
}) => {
  const { mayRefund } = useFinanceActions()
  return (
    <>
      {
        !isPayCreditPayment && isPaidPaymentLink && isResponsiblePaymentCompany && !isPayPixPayment &&
          <TooltipOnHover text={`Editar ${label}`}>
            <PencilIcon css={iconClick} onClick={onEdit} />
            <Label css={iconLabel(card)} onClick={onEdit}>{`Editar ${label}`}</Label>
          </TooltipOnHover>
      }
      {
        !isRefund && isPaidPaymentLink &&
          <TooltipOnHover text={`Visualizar ${label}`}>
            <EyeIcon css={iconClick} onClick={onShow} />
            <Label css={iconLabel(card)} onClick={onShow}>{`Visualizar ${label}`}</Label>
          </TooltipOnHover>
      }
      {
        !isRefund && !isPaidPaymentLink &&
          <TooltipOnHover size={120} text='Visualizar link de pagamento'>
            <EyeIcon css={iconClick} onClick={onPaymentLinkShow} />
            <Label css={iconLabel(card)} onClick={onPaymentLinkShow}>Visualizar link de pagamento</Label>
          </TooltipOnHover>
      }
      {
        (isPayCreditPayment || isPayPixPayment) && isRefund &&
          <TooltipOnHover size={120} text='Visualizar estorno do link de pagamento'>
            <EyeIcon css={iconClick} onClick={onRefundLinkShow} />
            <Label css={iconLabel(card)} onClick={onRefundLinkShow}>Visualizar estorno do link de pagamento</Label>
          </TooltipOnHover>
      }
      {
        !isPayCreditPayment && isPaidPaymentLink && isResponsiblePaymentCompany && !isPayPixPayment &&
          <TooltipOnHover text={`Excluir ${label}`}>
            <TrashIcon css={iconClick} onClick={onDelete} />
            <Label css={iconLabel(card)} onClick={onDelete}>{`Excluir ${label}`}</Label>
          </TooltipOnHover>
      }
      {
        (isPayCreditPayment || isPayPixPayment) && isPaidPaymentLink && !isRefund && mayRefund &&
          <TooltipOnHover text={`Reembolsar ${label}`}>
            <RefoundIcon css={iconClick} onClick={onRefundSplit} />
            <Label css={iconLabel(card)} onClick={onRefundSplit}>{`Reembolsar ${label}`}</Label>
          </TooltipOnHover>
      }
      {
        (isCreditPayment || isDebitCreditPayment) && !isRefund && mayRefund &&
        <TooltipOnHover text={`Reembolsar ${label}`}>
          <RefoundIcon css={iconClick} onClick={onRefoundInline} />
          <Label css={iconLabel(card)} onClick={onRefoundInline}>{`Reembolsar ${label}`}</Label>
        </TooltipOnHover>
      }
      {
        isOptionEnabled &&
          <TooltipOnHover size={80} text='Copiar link'>
            <CopyIcon css={iconClick} onClick={onCopy} />
            <Label css={iconLabel(card)} onClick={onCopy}>Copiar link</Label>
          </TooltipOnHover>
      }
      {
        isOptionEnabled &&
          <TooltipOnHover text='Pagar'>
            <SendIcon css={[iconClick, marginHorizontal8]} onClick={onRedirect} />
            <Label css={iconLabel(card)} onClick={onRedirect}>Pagar</Label>
          </TooltipOnHover>
      }
    </>
  )
}

export const ActionsBodyCell = ({
  id,
  payment,
  isSent,
  responsiblePaymentCompanyId,
  card = false,
  mayRefund,
  isRefund = false,
  isPaidPaymentLink = false,
  reservationPaymentId
}) => {
  const {
    onPaymentShow,
    onPaymentLinkShow,
    onRefundLinkShow,
    onPaymentEdit,
    onPaymentDelete,
    onRefundDelete,
    onRefundEdit,
    onRefundSplitCreation,
    onRefundCreation,
    onCopyLink,
    onRedirectLinkToPay
  } = useFinanceActions()

  const { id: companyId } = useCompany()

  const label = isRefund ? 'reembolso' : 'pagamento'

  const isGatewayPagarMe = payment?.gateway === GATEWAYS_MAP.pagar_me

  const onShow = () => onPaymentShow(reservationPaymentId, isRefund, payment)
  const onPaymentLinkView = () => onPaymentLinkShow(id)
  const onRefundLinkView = () => onRefundLinkShow(id, payment)
  const onEdit = () => isRefund ? onRefundEdit(id) : onPaymentEdit(id)
  const onDelete = () => isRefund ? onRefundDelete(id) : onPaymentDelete(id)
  const onRefundSplit = () => onRefundSplitCreation(reservationPaymentId)
  const onRefoundInline = () => onRefundCreation(id)
  const onCopy = () => onCopyLink(id, isGatewayPagarMe)
  const onRedirect = () => onRedirectLinkToPay(id, isGatewayPagarMe)
  const isPayCreditPayment = payment?.method === 'pay_credit'
  const isCreditPayment = payment?.method === 'credit'
  const isDebitCreditPayment = payment?.method === 'debit'
  const isPayPixPayment = payment?.method === 'pay_pix'
  const isOptionEnabled = isSent
  const isResponsiblePaymentCompany = !responsiblePaymentCompanyId || companyId === responsiblePaymentCompanyId

  const reference = useRef()
  const [isOptionsVisible, setOptionsVisibility] = useClickOutside(reference)
  const toggleOptionsVisible = useCallback(() => {
    setOptionsVisibility(isOpen => !isOpen)
  }, [setOptionsVisibility])

  return (
    <TableBodyCell data-label='Ação' fixed>
      <div css={flex}>
        <div css={options(card)} onClick={toggleOptionsVisible}>
          <EllipsisIcon css={optionsIcon} />
        </div>

        <div css={inlineOptions(card)}>
          <ReservationPaymentOptions
            label={label}
            card={card}
            mayRefund={mayRefund}
            isPaidPaymentLink={isPaidPaymentLink}
            isRefund={isRefund}
            onShow={onShow}
            onPaymentLinkShow={onPaymentLinkView}
            onRefundLinkShow={onRefundLinkView}
            onEdit={onEdit}
            onDelete={onDelete}
            onRefundSplit={onRefundSplit}
            onRefoundInline={onRefoundInline}
            onCopy={onCopy}
            onRedirect={onRedirect}
            isPayCreditPayment={isPayCreditPayment}
            isPayPixPayment={isPayPixPayment}
            isCreditPayment={isCreditPayment}
            isDebitCreditPayment={isDebitCreditPayment}
            isOptionEnabled={isOptionEnabled}
            isResponsiblePaymentCompany={isResponsiblePaymentCompany}
          />
        </div>
        <Dialog customContainercss={[dialog]} title='Ação' isVisible={isOptionsVisible} onClose={toggleOptionsVisible}>
          <div css={optionsContainer}>
            <ReservationPaymentOptions
              label={label}
              card={card}
              mayRefund={mayRefund}
              isPaidPaymentLink={isPaidPaymentLink}
              isRefund={isRefund}
              onShow={onShow}
              onPaymentLinkShow={onPaymentLinkView}
              onRefundLinkShow={onRefundLinkShow}
              onEdit={onEdit}
              onDelete={onDelete}
              onRefundSplit={onRefundSplit}
              onRefoundInline={onRefoundInline}
              onCopy={onCopy}
              onRedirect={onRedirect}
              isPayCreditPayment={isPayCreditPayment}
              isPayPixPayment={isPayPixPayment}
              isCreditPayment={isCreditPayment}
              isDebitCreditPayment={isDebitCreditPayment}
              isOptionEnabled={isOptionEnabled}
              isResponsiblePaymentCompany={isResponsiblePaymentCompany}
            />
          </div>
        </Dialog>
      </div>
    </TableBodyCell>
  )
}
