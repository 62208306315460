/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { Accordion, CollapsableSection, useToast, HeaderPage } from '@bonitour/components'
import { CreateActivityInfo } from './CreateActivityInfo'

export const CreateActivity = () => {
  const { add: addToast } = useToast()
  const onAccordionClick = value => value !== 0 && addToast('Preencha as informações básicas')
  const { push } = useHistory()

  const backToHome = () => push('/app')
  return (
    <>
      <HeaderPage onBack={backToHome} title='Atividade' />
      <Accordion value={1} onClick={onAccordionClick}>
        <CollapsableSection
          indicator={1}
          title='Informações básicas'
          topics='Registro / Contato / Endereço'
        >
          <CreateActivityInfo />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Tempo de atividade / Nível de dificuldade'
        />

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso na atividade / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre a atividade em plataformas de vendas'
        />

        <CollapsableSection indicator={4} title='Vagas' topics='Limite de vagas / Horários' />

        <CollapsableSection
          indicator={5}
          title='Tarifário'
          topics='Tarifário padrão / Tipificação de PAX / Tarifário por demanda'
        />
      </Accordion>
    </>
  )
}
