/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { css, jsx } from '@emotion/core'
import { Button, Dialog, Label, PlusIcon, RadioGroup, RadioInputGroup } from '@bonitour/components'
import { cardButton, cardIcon, labelCardButton } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { AddButton } from 'components/AddButton'
import { identity } from '@bonitour/common-functions'

const dialogStyle = css`
  min-width: 500px;
`
const radioGroupStyle = css`
  margin: 20px 0 25px;
`
const singleLineAddButton = css`
  padding: 10px 5px;
  span {
    margin-left: 0;
  }
`

export default function NewExperienceButton ({
  singleLine = false,
  onNavigation: emitNavigationEvent = identity
}) {
  const { push } = useHistory()

  const [newServiceType, setNewServiceType] = useState('')
  const [newServiceDialog, setNewServiceDialog] = useState(false)
  const closeDialog = useCallback(() => {
    setNewServiceType('')
    setNewServiceDialog(false)
  }, [])
  const openDialog = useCallback(() => setNewServiceDialog(true), [])

  const onNewService = useCallback(() => {
    if (newServiceType) {
      closeDialog()
      push(`/${newServiceType}/create`)
      emitNavigationEvent()
    }
  }, [newServiceType, push, closeDialog, emitNavigationEvent])

  const BaseButton = useCallback(({ onClick }) => singleLine
    ? (
      <AddButton onClick={onClick} css={singleLineAddButton}>
      Nova Experiência
      </AddButton>
    )
    : (
      <CardButton css={cardButton} onClick={onClick}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>Nova experiência</Label>
      </CardButton>
    ), [singleLine])

  return (
    <>
      <BaseButton onClick={openDialog} />
      <Dialog title='Selecione o tipo de experiência' isVisible={newServiceDialog} onClose={closeDialog} customCss={[dialogStyle]}>
        <RadioGroup value={newServiceType} name='newServiceType' onChange={setNewServiceType} customCss={[radioGroupStyle]}>
          {[
            <RadioInputGroup key='activity' id='activity' value='activity'>Atividade</RadioInputGroup>,
            <RadioInputGroup key='transport' id='transport' value='transport'>Transporte</RadioInputGroup>,
            <RadioInputGroup key='offline-experience' id='offline-experience' value='offline-experience'>Experiência Offline</RadioInputGroup>
          ]}
        </RadioGroup>
        <Button disabled={!newServiceType} onClick={onNewService}>Continuar</Button>
      </Dialog>
    </>
  )
}
