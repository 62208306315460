/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, InputFormGroup, colors, Button, useToast, Row, Column, DatePicker, Input } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { string, date } from 'yup'
import { useValidateDateRange } from 'domains/CompanyReports/hooks/useValidadeDateRange'
import { useKeyPress } from 'hooks/useKeyPress'

const button = css`
  height: 40px;
  width: 100%;
  margin-top: 20px;
`
const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

const width150 = css`
  width: 150px;
  width: 100%;
`

export const RevenueFilterSchema = {
  initialDate: date().nullable(),
  finalDate: date().nullable(),
  fiscalDuty: string().nullable(),
  reservationCode: string().nullable(),
  payerName: string().nullable()
}

export const RevenueFilter = ({
  onFilterSubmit: emitFilterSubmit = identity,
  initialFilters = {}
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputChange } } = useForm(initialFilters, RevenueFilterSchema)
  const { initialDate, finalDate, fiscalDuty = '', reservationCode = '', payerName = '' } = form
  const { validateDateRange } = useValidateDateRange()
  const { onKeyDown } = useKeyPress()

  const onError = () => addToast('Preencha o formulário corretamente')

  const validateFilters = (filters) => {
    validateDateRange(initialDate, finalDate) && emitFilterSubmit(filters)
  }

  const onClick = onSubmit(validateFilters, onError)

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              value={initialDate}
              onChange={onInputChange('initialDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              value={finalDate}
              onChange={onInputChange('finalDate')}
              css={width150}
              allowsEmpty
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Nota fiscal' errorMessage={errors.fiscalDuty}>
            <Input
              value={fiscalDuty}
              error={errors.fiscalDuty}
              onChange={onInputChange('fiscalDuty')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Código da reserva' errorMessage={errors.reservationCode}>
            <Input
              value={reservationCode}
              error={errors.reservationCode}
              onChange={onInputChange('reservationCode')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <InputFormGroup label='Nome do pagador' errorMessage={errors.payerName}>
            <Input
              value={payerName}
              error={errors.payerName}
              onChange={onInputChange('payerName')}
              onKeyDown={event => onKeyDown(event, onClick)}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletlandscape={3} desktop={3}>
          <Button css={button} onClick={onClick}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}
