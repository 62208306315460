/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { HeaderPage, Row, TabContainer, UnderlineNavContainer, UnderlineNavItem, UnderlineTabPane } from '@bonitour/components'
import { hidden } from 'assets/styles/global'
import { useQuery } from 'hooks/useQuery'
import { useActivity } from 'contexts/Activity'
import { Activities } from 'containers/Company/Dashboard/List/Activities/Activities'
import { container, underlineNavContainer, tabPaneContainer } from './ActivitiesPage.style'
import NewExperienceButton from './NewExperienceButton'
import { useOfflineExperiences } from 'containers/Company/Dashboard/List/Activities/hooks/useOfflineExperiences'

const ActivitiesPage = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/app')
  const { companyActivities, partnerActivities } = useActivity()
  const { offlineExperiences } = useOfflineExperiences()

  const hasCompanyActivities = useMemo(() => companyActivities.length > 0, [companyActivities])
  const hasPartnerActivities = useMemo(() => partnerActivities.length > 0, [partnerActivities])
  const hasOfflineExperiences = useMemo(() => offlineExperiences?.length > 0, [offlineExperiences])

  const [{
    selectedNav = hasCompanyActivities ? 'myExperiences' : 'partnerExperiences'
  }] = useQuery()

  const setSelectedNav = (value) => push(`?selectedNav=${value}`)

  useEffect(() => {
    (!hasCompanyActivities && !hasPartnerActivities) && setSelectedNav('')
    // eslint-disable-next-line
  }, [hasCompanyActivities, hasPartnerActivities])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Experiências' />

      <div css={container}>
        <NewExperienceButton />
      </div>
      {(hasCompanyActivities || hasPartnerActivities) && (
        <Row>
          <UnderlineNavContainer css={underlineNavContainer} selectedNav={selectedNav} onChange={setSelectedNav}>
            {[
              <UnderlineNavItem navId='myExperiences' key='myExperiences' css={!hasCompanyActivities && hidden}>Minhas experiências</UnderlineNavItem>,
              <UnderlineNavItem navId='partnerExperiences' key='partnerExperiences' css={!hasPartnerActivities && hidden}>Experiências de parceiros</UnderlineNavItem>,
              <UnderlineNavItem navId='offlineExperiences' key='offlineExperiences' css={!hasOfflineExperiences && hidden}>Experiências offline</UnderlineNavItem>
            ]}
          </UnderlineNavContainer>

          <TabContainer activeTab={selectedNav}>
            <UnderlineTabPane css={[tabPaneContainer, !hasCompanyActivities && hidden]} tabId='myExperiences'>
              <Activities companyActivities={companyActivities.sort((a, b) => a.name.localeCompare(b.name))} isFromActivitiesPage />
            </UnderlineTabPane>
            <UnderlineTabPane css={[tabPaneContainer, !hasPartnerActivities && hidden]} tabId='partnerExperiences'>
              <Activities partnerActivities={partnerActivities.sort((a, b) => a.name.localeCompare(b.name))} isFromActivitiesPage isPartner />
            </UnderlineTabPane>
            <UnderlineTabPane css={[tabPaneContainer, !hasOfflineExperiences && hidden]} tabId='offlineExperiences'>
              <Activities isFromActivitiesPage isOffline />
            </UnderlineTabPane>
          </TabContainer>
        </Row>
      )}
    </>
  )
}

export default ActivitiesPage
