import { string, number, object, array } from 'yup'
import { vacancySchedulesSchema } from './VacancySchedules/VacancySchedules.schema'
import { periodsSchema } from 'containers/Periods/Periods.schema'
import { getUniqueValues } from '@bonitour/common-functions'

const isSomeDataDuplicated = (originalArray) => {
  const newArray = getUniqueValues(originalArray)
  return originalArray.length !== newArray.length
}

export const activityVacancySchema = {
  activityVacancy: object({
    vacancyName: string().required('O nome da vaga é obrigatório'),
    dailyLimit: number()
      .typeError('Deve ser um número')
      .required('O limite diário obrigatório')
      .min(1, 'O limite deve ser no mínimo 1')
  }),
  periodList: array().of(object().shape(periodsSchema)),
  scheduleList: array().of(object().shape(vacancySchedulesSchema))
    .min(1, 'Adicione uma vaga')
    .test('has-not-equal-hours', 'Não devem haver intervalos de horários iguais', (formData) => {
      const hourArray = formData.map(({ time }) => time)
      return !isSomeDataDuplicated(hourArray)
    })
}
