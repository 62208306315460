/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney, identity } from '@bonitour/common-functions'
import { BREAK_POINTS, Button, Card, colors, Dialog, flexColumn, GhostPrimaryButton } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { ActivityListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { OfflineExperiencesTicketForm } from 'containers/OfflineExperiencesTicketForm/OfflineExperiencesTicketForm'
import { ReservationSelector } from 'containers/Reservations/ReservationList/ReservationSelector'
import { useState, useCallback, useMemo } from 'react'

const summary = css`
  background-color: #F4F4F4;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: 0px -3px 6px #00000010;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: none;
  }
`

const summaryContent = css`
  position: relative;
  max-width: 1268px;
  padding: 15px;
  display: flex;
  margin: auto;
`

const amountDueCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 20px;
  padding: 20px;
`

const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const amountDueStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
  font-weight: bold;
`

const continueButton = css`
  position: relative;
  padding: 10px 20px;
  display: inline-block;
  margin: auto;
  margin-right: 40px;  
`

const buttonsArea = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`

const selectorStyle = css`
  margin: 0;
  max-height: 400px;
`

const dialogContainer = css`
  max-width: 800px;
`

const newReservationCss = css`
  display: inline-block;
  width: auto;
`

export const fiscalDutyDialog = css`
  p {
    margin-bottom: 10px;
  }
  button {
    margin-right: 20px;
  }
`

export const TicketSummary = ({ total = 0, onActivitySelect = identity, reservation, onTicketUpdate = identity }) => {
  const [openedSelector, setOpenedSelector] = useState(null)
  const [fiscalDutyBlocked, setFiscalDutyBlocked] = useState(false)
  const closeFiscalDutyDialog = useCallback(() => setFiscalDutyBlocked(false), [])
  const hasFiscalDuty = useMemo(() => Boolean(reservation?.fiscalDuty), [reservation?.fiscalDuty])
  const handleOpenedSelector = useCallback((type, dialogConfirmation = false) => {
    if (hasFiscalDuty && !dialogConfirmation) {
      setFiscalDutyBlocked(true)
      return
    }
    setOpenedSelector(type)
  }, [hasFiscalDuty])

  const onCloseSelector = useCallback(() => {
    setOpenedSelector(null)
  }, [setOpenedSelector])

  const isActivitySelectorOpen = useMemo(() => openedSelector === 'activity', [openedSelector])
  const isOfflineExperienceSelectorOpen = useMemo(() => openedSelector === 'offlineExperience', [openedSelector])

  const onClickActivity = () => {
    onActivitySelect()
    onCloseSelector()
  }

  return (
    <>
      <div css={summary}>
        <div css={summaryContent}>
          <Card css={amountDueCard}>
            <div css={flexColumn}>
              <p css={amountDueLabel}>Valor total</p>
              <span css={amountDueStyle}>{formatMoney(total || 0)}</span>
            </div>
          </Card>
          <div css={buttonsArea}>
            <GhostPrimaryButton css={continueButton} onClick={() => handleOpenedSelector('offlineExperience')}>
              Adicionar ingresso offline
            </GhostPrimaryButton>
            <GhostPrimaryButton css={continueButton} onClick={() => handleOpenedSelector('activity')}>
              Continuar reservando
            </GhostPrimaryButton>
          </div>
        </div>
      </div>

      <Dialog
        customContainercss={[fiscalDutyDialog]}
        title='Reserva com NF emitida!'
        isVisible={fiscalDutyBlocked}
        onClose={closeFiscalDutyDialog}
      >
        <p>
          Não é possível adicionar novos ingressos a esta reserva pois ela possui uma nota fiscal emitida.
        </p>
        <p>
          Para reservar novos ingressos, é necessário criar uma nova reserva.
        </p>
        <ReservationSelector subdomain='bookings' customCss={[newReservationCss]} email={reservation?.info?.email}>
          <Button>Criar nova reserva</Button>
        </ReservationSelector>
        <GhostPrimaryButton onClick={closeFiscalDutyDialog}>Cancelar</GhostPrimaryButton>
      </Dialog>
      <Dialog customContainercss={[dialogContainer]} title='Vincular experiência' isVisible={isActivitySelectorOpen} onClose={onCloseSelector}>
        <ActivityListSelector
          css={selectorStyle}
          activitiesMaxHeight={50}
          partnersMaxHeight={50}
          onNavigation={onClickActivity}
          subdomain={null}
          showCreateButton={false}
        />
      </Dialog>
      <Dialog customContainercss={[dialogContainer]} title='Adicionar experiência offline' isVisible={isOfflineExperienceSelectorOpen} onClose={onCloseSelector}>
        <OfflineExperiencesTicketForm
          reservationId={reservation?.id}
          reservationInfo={reservation?.info}
          onClose={onCloseSelector}
          onTicketUpdate={onTicketUpdate}
        />
      </Dialog>
    </>
  )
}
