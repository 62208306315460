/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { Button, Dialog, GhostButton, Label, Row, WarningAlert } from '@bonitour/components'
import {
  actionContainerStyle,
  containerDialogStyle,
  labelStyle
} from './BookingEditionDiffStyles'
import { BookingEditionDiffTable } from './BookingEditionDiffTable'
import { formatMoney } from '@bonitour/common-functions'
import { flexEnd } from 'domains/PartnershipAccount/Edit/PartnershipTransactions.styles'

export default function BookingEditionDiffDialog ({ isVisible, onClose, modalData = {} }) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const submitButtonLabel = useMemo(() => {
    if (modalData?.values?.priceProperties?.isEqual) {
      return 'Confirmar'
    } else {
      return 'Confirmar diferença'
    }
  }, [modalData?.values?.priceProperties?.isEqual])

  const onSubmit = useCallback(() => {
    setIsLoadingSubmit(true)
    modalData.onEditClick()
      .finally(() => {
        setIsLoadingSubmit(false)
        onClose()
      })
  }, [modalData, onClose])

  const onCloseClick = useCallback(() => {
    if (isLoadingSubmit) return
    onClose()
  }, [isLoadingSubmit, onClose])

  const modalTitle = useMemo(() => {
    if (modalData?.values?.priceProperties?.isEqual) return 'Confirmar alteração de data'
    return 'Confirmar mudança de tarifário'
  }, [modalData?.values?.priceProperties?.isEqual])

  const hasDiff = useMemo(() => !modalData?.values?.priceProperties?.isEqual, [modalData?.values?.priceProperties?.isEqual])

  const totalPrice = useMemo(() => modalData?.values?.priceProperties?.newPrice, [modalData?.values])

  return <Dialog
    isVisible={isVisible}
    onClose={onCloseClick}
    customContainercss={containerDialogStyle}
    title={modalTitle}
  >
    {!modalData?.values?.priceProperties?.isEqual
      ? (
        <WarningAlert>
          <p>
            Atenção, ao realizar a alteração de data haverá mudança no valor, verifique abaixo
          </p>
        </WarningAlert>
      )
      : ''}
    <BookingEditionDiffTable modalData={modalData?.values}/>
    {hasDiff
      ? <Row customCss={[flexEnd]}>
        <Label css={labelStyle}>
    Novo total: {formatMoney(totalPrice)}
        </Label>
      </Row>
      : ''}
    <div css={actionContainerStyle}>
      <GhostButton onClick={onCloseClick}>
        Cancelar
      </GhostButton>
      <Button onClick={onSubmit} disabled={isLoadingSubmit}>
        {submitButtonLabel}
      </Button>
    </div>
  </Dialog>
}
