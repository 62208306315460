import { css } from '@emotion/core'
import { BREAK_POINTS, colors, scrollBar } from '@bonitour/components'

export const label = css`
  font-size: 14px;
  color: ${colors.gray2};
  margin: 10px 0;
`

export const searchIcon = css`
  font-size: 20px;
`

export const listContainer = css`
  margin: 20px 0;
  ${scrollBar(5)}
`

export const activitiesContainer = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    justify-content: center;
  }
`
export const partnerActivityCard = css`
  min-width: 168px;
  width: 168px;
  padding: 10px 0 5px 10px;
  margin: 0 10px 20px 10px;
`

export const activityCard = css`
  width: 225px;
  height: 250px;
  padding: 0;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 138px;
    height: 200px;
  }
`

export const search = css`
  width: 440px;
  margin-left: 10px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
    margin: 0;
  }
`

export const offlineSearch = css`
  ${search}
  width: 300px;
  margin-right: 10px;
`
