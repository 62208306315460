/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Manager, Reference, Popover } from '@bonitour/components'
import { useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { clickable } from 'assets/styles/global'
import { UserSection } from '../TopNavbar/UserSection'
import { UserOptions } from '../TopNavbar/UserOptions'

export const UserGuide = () => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)

  const toggleVisibility = () => setVisibility(visibility => !visibility)
  return (
    <div ref={reference}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div css={clickable} ref={ref} onClick={toggleVisibility}>
              <UserSection />
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom'>
            <UserOptions />
          </Popover>
        )}
      </Manager>
    </div>
  )
}
