import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useActivity } from 'contexts/Activity'

export const getCorrectPath = (type, route) => {
  switch (type) {
  case 'Experiences::OfflineExperience':
    return (`/offline-experience/${route}`)

  case 'Experiences::Transport':
    return (`/transport/${route}`)

  case 'Experiences::Activity':
  default:
    return (`/activity/${route}`)
  }
}

export const useRouteByType = (route) => {
  const { activity: { type, id } } = useActivity()

  const { push } = useHistory()
  const { pathname } = useLocation()

  const correctPath = useMemo(() => getCorrectPath(type, route), [type, route])
  const basePath = useMemo(() => correctPath?.split('/').slice(0, 3).join('/'), [correctPath])

  useEffect(() => {
    if (correctPath !== pathname && pathname?.includes(id)) {
      push(correctPath)
    }
  }, [correctPath, push, pathname, id])

  return { basePath }
}
