import { css } from '@emotion/core'

export const selectorContainer = css`
  width: 150px;
`
export const popoverStyle = css`
  margin: 0 20px;
  padding-left: 20px;
  
  text-align: left;

  div {
    top: 25px;
  }
`
export const SellectorTitle = css`
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
`

export const dialogContainer = css`
  max-width: 800px;
`

export const selectorStyle = css`
  margin: 0;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`
