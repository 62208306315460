/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useMemo, useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { SearchIcon, InputWithSuffix, Select, LoadingAnimation } from '@bonitour/components'
import { listContainer, label, searchIcon, maxHeight, fullWidth, offlineSearchRow } from './ActivitiesList.style'
import { ActivityCard } from '../ActivityCard'
import { useDebounce } from '@bonitour/app-functions'
import NewExperienceButton from 'domains/Activity/NewExperienceButton'
import { useOfflineExperiences } from 'containers/Company/Dashboard/List/Activities/hooks/useOfflineExperiences'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { offlineExperienceCategoriesFilterOptions } from 'containers/Company/Dashboard/List/Activities/OfflineExperiences'
import { useExternalProviderOptions } from 'domains/ExternalProviders/hooks/useExternalProviderOptions'
import { loadingContainer } from 'assets/styles/global'

const ActivitiesList = ({
  activities = [],
  emitNavigationEvent,
  subdomain,
  disableLink = false,
  email = ''
}) => {
  return (
    activities.map(activity => (
      <ActivityCard
        key={`activity-${activity.id}`}
        activity={activity}
        onActivityClick={() => emitNavigationEvent(activity.id)}
        subdomain={subdomain}
        hasTitleMaxWidth={false}
        disableLink={disableLink}
        email={email}
      />
    ))
  )
}

export const CompanyActivities = ({
  companyActivities = [],
  showCreateButton = true,
  subdomain,
  activitiesMaxHeight = 28,
  onNavigation: emitNavigationEvent = identity,
  email = ''
}) => {
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)

  const filteredActivities = useMemo(() => {
    if (queryWithDebounce) {
      return companyActivities.filter(({ name, companyName }) => {
        const activityQueryLowercase = queryWithDebounce.toLowerCase()
        const activityNameLowecase = name.toLowerCase()
        const companyNameLowecase = companyName.toLowerCase()
        const hasQueryInName = activityNameLowecase.includes(activityQueryLowercase)
        const hasQueryInCompanyName = companyNameLowecase.includes(activityQueryLowercase)
        return hasQueryInName || hasQueryInCompanyName
      })
    }
    return companyActivities
  }, [companyActivities, queryWithDebounce])

  if (!companyActivities.length) {
    return null
  }

  return (
    <>
      <p css={label}>Minhas experiências</p>
      <div>
        <InputWithSuffix css={fullWidth} value={query} onChange={setQuery} placeholder='Busca'>
          <SearchIcon css={searchIcon} />
        </InputWithSuffix>
      </div>
      <div css={[listContainer, maxHeight(activitiesMaxHeight)]}>
        <ActivitiesList activities={filteredActivities} emitNavigationEvent={emitNavigationEvent} subdomain={subdomain} email={email} />
      </div>
      {showCreateButton && (
        <NewExperienceButton singleLine onNavigation={emitNavigationEvent} />
      )}
    </>
  )
}

export const PartnerActivities = ({
  partnerActivities = [],
  subdomain,
  partnersMaxHeight = 40,
  onNavigation: emitNavigationEvent = identity,
  email = ''
}) => {
  const [query, setQuery] = useState('')
  const queryWithDebounce = useDebounce(query, 200)

  const filteredActivities = useMemo(() => {
    if (queryWithDebounce) {
      return partnerActivities.filter(({ name, companyName }) => {
        const activityQueryLowercase = queryWithDebounce.toLowerCase()
        const activityNameLowecase = name.toLowerCase()
        const companyNameLowecase = companyName.toLowerCase()
        const hasQueryInName = activityNameLowecase.includes(activityQueryLowercase)
        const hasQueryInCompanyName = companyNameLowecase.includes(activityQueryLowercase)
        return hasQueryInName || hasQueryInCompanyName
      })
    }
    return partnerActivities
  }, [partnerActivities, queryWithDebounce])

  if (!partnerActivities.length) {
    return null
  }

  return (
    <>
      <p css={label}>Parceiros comerciais</p>
      <div>
        <InputWithSuffix css={fullWidth} value={query} onChange={setQuery} placeholder='Busca'>
          <SearchIcon css={searchIcon} />
        </InputWithSuffix>
      </div>
      <div css={[listContainer, maxHeight(partnersMaxHeight)]}>
        <ActivitiesList activities={filteredActivities} emitNavigationEvent={emitNavigationEvent} subdomain={subdomain} email={email} />
      </div>
    </>
  )
}

export const OfflineExperiences = ({
  activitiesMaxHeight = 28,
  subdomain,
  onNavigation: emitNavigationEvent = identity,
  disableLink = false,
  hideTitle = false,
  email = ''
}) => {
  const {
    offlineExperiences,
    loadNextPage,
    isLoading,
    category,
    setCategory,
    query,
    setQuery,
    offlineProviderId,
    setOfflineProviderId
  } = useOfflineExperiences()
  const { externalProvidersOptions } = useExternalProviderOptions()

  const scrollRef = useRef()

  return (
    <>
      {!hideTitle && <p css={label}>Experiências offline</p>}
      <div css={offlineSearchRow}>
        <InputWithSuffix value={query} onChange={setQuery} placeholder='Busca'>
          <SearchIcon css={searchIcon} />
        </InputWithSuffix>
        <Select
          placeholder='Categorias'
          options={offlineExperienceCategoriesFilterOptions}
          value={category}
          onChange={setCategory}
        />
        <Select
          placeholder='Fornecedores'
          options={externalProvidersOptions}
          value={offlineProviderId}
          onChange={setOfflineProviderId}
        />
      </div>
      <div css={[listContainer, maxHeight(activitiesMaxHeight)]} ref={scrollRef}>
        <ActivitiesList activities={offlineExperiences} emitNavigationEvent={emitNavigationEvent} subdomain={subdomain} disableLink={disableLink} email={email}/>
        <InfiniteScroll nextPage={loadNextPage} loading={isLoading} scrollElement={scrollRef.current} />
        {isLoading && <LoadingAnimation css={loadingContainer} />}
      </div>
    </>
  )
}
