import { orbCore } from 'services/Orb/Service'
import { RefundParser } from 'core/parsers/Refund'
import { refundModel, refundPayModel } from 'core/models/Refund'
import { convertToFormData } from '@bonitour/common-functions'
import { CompanyStore } from 'core/store/Company'
import { UserStore } from 'services/User/Store'
import { ReservationAdvanceRefundModel } from 'core/models/Reservation/ReservationAdvanceFinance'

const { reservationRefundDomain, reservationRefundDomainV2, providerRefundDomain } = orbCore

const companyId = CompanyStore.getId()
const { id: userId } = UserStore.getUser()

export const RefundService = {
  createRefund (refund, reservationId) {
    const payload = refundModel(refund)
    return reservationRefundDomainV2.create(convertToFormData(payload), reservationId)
  },
  createPartnershipRefund (reservationId, refund) {
    const payload = ReservationAdvanceRefundModel(refund)
    return providerRefundDomain.create(convertToFormData(payload), reservationId)
  },
  createRefundPay (refund, reservationId, paymentId) {
    const payload = refundPayModel(companyId, userId, refund)
    return reservationRefundDomain.createPay(convertToFormData(payload), reservationId, paymentId)
  },
  editRefund (refund, reservationId) {
    return reservationRefundDomain.edit(convertToFormData(refundModel(refund)), reservationId, refund.id)
  },
  getById (reservationId, refundId) {
    return reservationRefundDomain.getById(reservationId, refundId).then(RefundParser.singleRefund)
  },
  getPartnershipById (reservationId, refundId) {
    return providerRefundDomain.getById(reservationId, refundId).then(RefundParser.singlePartnershipRefund)
  },
  getRefundLinkById (reservationId, refundId) {
    return reservationRefundDomain.getById(reservationId, refundId).then(RefundParser.singleRefundLink)
  },
  remove (reservationId, refundId, param) {
    return reservationRefundDomain.remove(reservationId, refundId, RefundParser.remove(param))
  }
}
