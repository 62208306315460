import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'

export const selectorContainer = css`
  display: flex;
  flex-direction: column;
  ${scrollBar(5)}
`

export const searchIcon = css`
  font-size: 20px;
`

export const label = css`
  font-size: 14px;
  color: ${colors.gray2};
  margin: 10px 0;
`

export const listContainer = css`
  min-height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  ${scrollBar(5)}
`

export const maxHeight = height => css`
  max-height: ${height}vh;
`

export const fullWidth = css`
  width: 100%;
`

export const offlineSearchRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    width: calc(calc(100% / 3) - 5px);
  }
`
