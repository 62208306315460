/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, BaseTooltip, flexRowCentered, flexCenteredSpaceBetweenRow, ConditionalView, flexColumn } from '@bonitour/components'
import { formatMoney } from '@bonitour/common-functions'
import { marginRight, marginTop } from 'assets/styles/global'
import { useHover } from '@bonitour/app-functions'

const marginTop10 = marginTop(10)

const priceStyle = textColor => css`
  background-color: ${colors.white};
  color: ${textColor};
  font-size: 12px;
  padding: 3px 10px;
  border-radius: var(--small-round-element-radius, 12px);
`

const container = css`
  position: relative;

  &.opacity {
    opacity: 0;
  }
`

const customTooltip = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25px;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
`

const fullWidth = css`
  width: 100%;
`

const priceContainer = css`
  margin-top: 5px;
  display: flex;
`

const priceLabels = css`
  ${flexColumn};
  align-items: flex-start;
  ${marginRight(10)};
`

const ageRange = css`
  font-size: 12px;
  font-weight: normal;
`

const Price = ({ label = '', value, minAge, maxAge, textColor = colors.gray6, ...other }) =>
  <div css={priceContainer} {...other}>
    {Boolean(label) &&
      <div css={priceLabels}>
        <span>{label}</span>
        <span css={ageRange}>{`${minAge} a ${maxAge} anos`}</span>
      </div>}
    <div css={priceStyle(textColor)}>{formatMoney(value)}</div>
  </div>

export const VacancyTablePrice = ({ dayPrices = [] }) => {
  const [ref, isVisible] = useHover(200)

  const defaultValue = dayPrices[0]?.pricing

  return (
    <div
      className={!defaultValue && 'opacity'}
      css={[container, flexRowCentered]}
      ref={ref}
    >
      <ConditionalView isVisible={isVisible}>
        <BaseTooltip position='bottom' css={customTooltip}>
          {dayPrices.map(({ name, pricing, minAge, maxAge }, index) =>
            <Price
              key={`day-price-${index}`}
              css={[flexCenteredSpaceBetweenRow, marginTop10, fullWidth]}
              textColor={colors.gray3}
              label={name}
              value={pricing}
              minAge={minAge}
              maxAge={maxAge}
            />
          )}
        </BaseTooltip>
      </ConditionalView>
      <Price value={defaultValue} />
    </div>
  )
}
